import React, { useCallback, useEffect, useState } from 'react';
import {Carticle } from '../data';
import MyAxiosClass from '../api/phpApi';
import HomeActuCompo from './HomeActuCompo';


export default function HomeActu({...props}) {
    const [articles, setArticles] = useState<Carticle[]>();
    const [imageFiles, setImageFiles] = useState();
 
    const getArticlesFromApi = useCallback(async()=>{
      const loadedData = await MyAxiosClass.getArticles();
      let tempArticles: Carticle[];
      if (loadedData !== undefined && loadedData.articles !==undefined && loadedData.articles.data !== undefined){      
          tempArticles = Object.values(loadedData.articles.data).map((anArticle: any)=>{
            let respArticle = new Carticle(parseInt(anArticle.article_id, 10), anArticle.article_title, anArticle.article_subtitle?anArticle.article_subtitle:"", anArticle.title, anArticle.at, parseInt(anArticle.category, 10), new Date(anArticle.dt_start), (anArticle.dt_end ? new Date(anArticle.dt_end):null), anArticle.details, anArticle.icon, parseInt(anArticle.company, 10), anArticle.image, anArticle.folder);
            respArticle.article_title = respArticle.article_title.toLowerCase();
            respArticle.article_subtitle = respArticle.article_subtitle.toLowerCase();
            respArticle.hour = anArticle.hour ? anArticle.hour : "";
            respArticle.option1 = (anArticle.option1 && (anArticle.option1.toString() === "1" || anArticle.option1 === true)) ? true : false;
            return respArticle;
          })
          if (tempArticles){
            let furtherArticles = tempArticles && tempArticles.filter((anArticle: Carticle)=>(new Date(anArticle.dt_start).getTime() >= new Date().getTime()))                 
            furtherArticles.sort((a, b) => new Date(a.dt_start).getTime() - new Date(b.dt_start).getTime());
            setArticles(furtherArticles);
          
          }
      }
    }, [])

    
    useEffect(() => {    
      getArticlesFromApi();
    }, [getArticlesFromApi])
    
  return (
           
            <div className='home-actu-compo-articles-wrapper'>                
                {articles && articles.map((anActu: Carticle, index: number)=>(                                           
                    <HomeActuCompo key={index} {...anActu}/> 
                ))}
                
            </div>
    
  )
}
