
import home03 from './images/home/Home03.jpeg';
import home02 from './images/home/Home02.jpeg';
import home04 from './images/home/Home04.jpg';
import home05 from './images/home/ingridReporter.jpg';
import home06 from './images/home/HomeArticle01.jpg';
import homeTeachBlock01 from './images/home/homeTeachBlock01.jpg';
import homeTeachBlock02 from './images/home/homeTeachBlock02.jpg';
import homeTeachBlock03 from './images/home/homeTeachBlock03.jpg';
import homeTeachBlock04 from './images/home/homeTeachBlock04.jpg';


import homePrestaBlock01 from './images/home/prestaBlock01.jpg'; 
import homePrestaBlock02 from './images/home/prestaBlock02.jpg';
import homePrestaBlock03 from './images/home/prestaBlock03.jpg';
import homePrestaBlock04 from './images/home/prestaBlock04.png';
import homePrestaBlock05 from './images/home/prestaBlock05.jpg';

import homePedaBlock01 from './images/home/homePeda01.jpg';
import homePedaBlock02 from './images/home/homePeda02.jpg';
import homePedaBlock03 from './images/home/homePeda03.jpg';
import homePedaBlock04 from './images/home/homePeda04.jpg';

import coachingIndividuel01 from './images/prestations/coaching.jpeg';
import coachingIndividuel02 from './images/prestations/coachingIndividuel02.jpeg';
import cp01 from './images/prestations/cp01.jpg';
import cp02 from './images/prestations/cp02.jpg';
import cp03 from './images/prestations/cp03.jpg';
import encadrementEnfants01 from './images/prestations/coursEnfantsUlsse.jpeg';
import encadrementEnfants02 from './images/prestations/coursEnfantsUlysse02.jpg';
import encadrementEnfants03 from './images/prestations/cavalerieUlysse.jpg';
import encadrementAdultes from './images/prestations/heroImgPhone.jpg';
import coachingEnfants01 from './images/prestations/coachingEnfants01.jpeg';

import coachingTrec from './images/prestations/stages02.jpg';
import coachingCollectif from './images/prestations/coachingCollectif.jpeg';
import ETCabre from './images/prestations/ET_cabre01.jpeg';
import longuesRenes02 from './images/prestations/longuesRenes02.jpeg';
import longuesRenes03 from './images/prestations/longuesRenesEpaule.jpg';
import jaleante from './images/prestations/monte.jpg';

import ptv01 from './images/prestations/ptv/ptv01.jpg' 
import ptv02 from './images/prestations/ptv/ptv02.jpg' 
import ptv03 from './images/prestations/ptv/ptv03.jpg' 
import ptv04 from './images/prestations/ptv/ptv04.jpg' 
import ptv05 from './images/prestations/ptv/ptv05.jpg' 
import ptv06 from './images/prestations/ptv/ptv06.jpg' 
import ptv07 from './images/prestations/ptv/ptv012.jpg' 
import ptv08 from './images/prestations/ptv/ptv08.jpg' 
import ptv09 from './images/prestations/ptv/ptv09.jpg' 
import ptv010 from './images/prestations/ptv/ptv010.jpg' 

import cross05 from './images/prestations/cross05.jpg';
import cross04 from './images/prestations/cross04.jpg';
import cross03 from './images/prestations/cross03.jpeg';
import cross02 from './images/prestations/cross02.jpg';
import cross01 from './images/prestations/cross01.jpg';

import lac01 from './images/prestations/lac_00.jpg';
import lac02 from './images/prestations/lac_01.jpg';
import lac03 from './images/prestations/lac_02.jpg';
import lac05 from './images/prestations/lac_04.jpg';
import ptvRond from './images/prestations/ptvRond.jpg';
import lac07 from './images/prestations/lac_06.jpg';

import mer01 from './images/prestations/mer01.jpeg';
import mer02 from './images/prestations/mer02.jpeg';
import mer03 from './images/prestations/mer03.jpeg';
import stages01 from './images/prestations/stages01.jpg';
import aideDebourrage01 from './images/prestations/aideDebourrage01.jpg';
import aideDebourrage02 from './images/prestations/aideDebourrage05.jpeg';
import aideDebourrage03 from './images/prestations/aideDebourrage03.png';
import aideDebourrage04 from './images/prestations/aideDebourrage04.png';

import comAnimale from './images/prestations/ComAnimale02.jpg';
import live01 from './images/partage/deviceCoachingVideo.png';
import live02 from './images/partage/deviceCoachingLive.png';
import live03 from './images/partage/deviceComAnimale.png';
import live04 from './images/partage/deviceComAnimaleTeaching.png';

import home01 from './images/home/Hero02.jpg';

import com04 from './images/prestations/com04.jpg';
import com05 from './images/prestations/com05.png';
import partageArticleLive from './images/partage/partage_article_live.png';
import partageArticleVideo from './images/partage/partage_article_video.png';
import partageArticleCom from './images/partage/partage_article_com.png';
import partageArticleComTeaching from './images/partage/partage_article_comTeaching.png';
import stagesSorties from './images/prestations/carouselPresta/background.png';
import aideDebourrage from './images/prestations/carouselPresta/aideDebourrage.jpg';
import coachingConcours from './images/prestations/carouselPresta/coachingConcours.jpg';
import comAnimalePresta from './images/prestations/carouselPresta/comAnimale.jpg';
import travailCheval from './images/prestations/carouselPresta/travailCheval.jpg';
import coursCo from './images/prestations/carouselPresta/coursCo.jpg';
import coursPart from './images/prestations/carouselPresta/coursPart.jpg';

import { GiAtSea, GiMeditation, GiTrophyCup } from 'react-icons/gi';
import { GiFlagObjective } from 'react-icons/gi';
import { GiTopHat } from 'react-icons/gi';
import {FaHorse} from 'react-icons/fa';
import {FaHatCowboy} from 'react-icons/fa';
import { TbHorseToy } from "react-icons/tb";

/* Photos témoignages */

import sarah from "./images/common/sarah.jpg";
import fantine from "./images/common/fantine.jpg";
import agnes from "./images/common/agnes.jpg";
import aurelie from "./images/common/aurelie.jpg";
import auriane from "./images/common/auriane.jpg";
import camille from "./images/common/camille.jpg";
import charlotte from "./images/common/charlotte.jpg";
import clem from "./images/common/clem.jpg";
import eva from "./images/common/eva.jpg";
import gaelleF from "./images/common/gaelleF.jpg";
import gaelleP from "./images/common/gaelleP.jpg";
import helene from "./images/common/helene.jpg";
import lea from "./images/common/lea.jpg";
import leaP from "./images/common/leaP.jpg";
import lnFloque from "./images/common/lnFloque.jpg";
import sabrina from "./images/common/sabrina.jpg";
import salwa from "./images/common/salwa.jpg";
import sandra from "./images/common/sandra.jpg";
import victoire from "./images/common/victoire.jpg";


import actuCoaching01 from './images/actus/actu_coaching01.jpeg';
import actuCoaching02 from './images/actus/actu_coaching02.jpeg';
import actuPied from './images/actus/actu_pied.jpg';
import actuCross from './images/actus/actu_cross.jpg';
import actuCom from './images/actus/actu_com.jpg';
import actuTrec from './images/actus/actu_trec.jpg';
import TargetFile from './components/TargetFile';
import { CgvParagraph, EquilysMail } from './components/SiteInfos';


export class ActivSection{
  id: string;
  isActiv: boolean;
  constructor(pId: string, pIsActiv: boolean){
    this.id = pId;
    this.isActiv = pIsActiv;
  }
}
export class CActuData{

  id: number;
  dt_start: Date;
  dt_end?:Date;
  title: string;
  details: string;
  at?: string;
  path?: string;
  image?: string;
  icon?: string;
  category: number;

  constructor(pId: number, pStartDate: Date, pEndDate: Date, pTitle: string, pDetails: string, pAt: string, pPath: string, pIcon: string, pCategory: number){
    this.id = pId;
    this.dt_start = pStartDate;
    this.dt_end = pEndDate;
    this.title = pTitle;
    this.details = pDetails;
    this.at = pAt;
    this.path = pPath;
    this.icon = pIcon;
    this.category = pCategory;
  }
}
export class Carticle{
  article_id: number;
  article_title: string;
  article_subtitle: string;
  title: string;
  at: string;
  category: number;
  dt_start: Date;
  dt_end: Date | null;
  details: string;
  icon: string;
  company: number;
  image: string;
  folder: string;
  option1?: boolean;
  hour?: string;

  constructor(
      pArticle_id: number, 
      pArticle_title: string,
      pArticle_subtitle: string,
      pTitle: string,
      pAt: string,
      pCategory: number,
      pDt_start: Date,
      pDt_end: Date | null,
      pDetails: string,
      pIcon: string,
      pCompany: number,
      pImage: string,
      pFolder: string
      ){
          this.article_id = pArticle_id;
          this.article_title= pArticle_title;
          this.article_subtitle= pArticle_subtitle;
          this.title = pTitle;
          this.at = pAt;
          this.category = pCategory;
          this.dt_start = pDt_start;
          this.dt_end = pDt_end;
          this.details = pDetails;
          this.icon = pIcon;
          this.company = pCompany;
          this.image = pImage;
          this.folder = pFolder;   
  }
}
export class COldActuData{

  id: number;
  date: string;
  title: string;
  details: string;
  at?: string;
  path?: string;
  image?: string;
  icon?: JSX.Element;
  
  constructor(pId: number, pDate: string, pTitle: string, pDetails: string, pAt: string, pPath: string, pIcon: JSX.Element){
    this.id = pId;
    this.date = pDate;
    this.title = pTitle;
    this.details = pDetails;
    this.at = pAt;
    this.path = pPath;
    this.icon = pIcon;
  }
}
export class CTrainingData{
  id: number;
  date: string;
  title: string;
  details: string;
  at: string;
  constructor(pId: number, pDate: string, pTitle: string, pDetails: string, pAt: string){
    this.id = pId;
    this.date = pDate;
    this.title = pTitle;
    this.details = pDetails;
    this.at = pAt;
  }
}

export class CwitnessData{
  id: number;
  details: string[];
  signature?: string;
  title?: string;
  photo?: string;
  constructor(pId: number, pDetails: string[], pSignature: string, pTitle: string){
    this.id = pId;
    this.details = pDetails;
    this.signature = pSignature;
  }
}
export class CteachingData{
  id: number;
  title: string;
  details: string[];
  icon?: JSX.Element;
  svg?: SVGElement;
  constructor(pId: number, pTitle: string, pDetails: string[]){
    this.id = pId;
    this.title = pTitle;
    this.details = pDetails;
  }
  
}
export class CPriceData{
  title: string;
  id: string;
  index: number;
  priceDetails: CpriceDetails[];
  constructor(pTitle: string, pId: string, pIndex: number, pPriceDetails: CpriceDetails[]){
    this.title = pTitle;
    this.id = pId;
    this.index = pIndex;
    this.priceDetails = pPriceDetails;
  }
}
export class CpriceDetails{
  id: number;
  label: string;
  price: string;
  title: string;
  label2?:string;
  constructor(pId: number, pLabel: string, pPrice: string, pTitle: string){
    this.id = pId;
    this.label = pLabel;
    this.price = pPrice;
    this.title = pTitle;

  }
}
export class CPrestaArticleData{
  id: number;
  details: string[];
  subtitle?: string;
  title: string;
  img: string[];
  button: string;

  constructor(pId: number, pTitle: string, pDetails: string[], pImg: string[], pButton: string){
    this.id = pId;
    this.title = pTitle;
    this.img = pImg;
    this.details = pDetails;
    this.button = pButton;
  }
}
export class CTogetherArticleData{
  id: number;
  title: string;
  img: string[];


  constructor(pId: number, pTitle: string, pDetails: string[], pImg: string[]){
    this.id = pId;
    this.title = pTitle;
    this.img = pImg;
  
  }
}

export interface Irider{
  lastname: string,
  firstname: string,
  birthdate: string,
  city: string,
  cp: string,
  address: string[],
  phone: string,
  mail: string,
  stable: string,
  licenceNb: string,
  level: number,
  problems: string,
  goals: string,
  dispos: string,
  signaturePlace: string,
  signatureDate: string,
  signatureName: string
}
export interface Icontact{
  lastname: string,
  firstname: string,
  contactName:string,
  is_allergic: boolean,
  allergies: string,
  childName: string,
  phone1: string,
  phone2:string,
  doctor: string,
  doctorPhone:string,
  blodGroup: string,
  acceptImage:boolean,
  cgvOk: boolean,
  signaturePlace: string,
  signatureDate: string,
  signatureName:string
} 
export interface Ibooking{
  id: string,
  label: string,
  toBook: boolean,
  option: string
}
export interface Iinfos{
  id: string,
  value: string | boolean,
  label: string,
  title: string

}
export class ChomeArticleData{
  id: number;
  details: string[];
  title: string;
  img: string;
  path?: string;
  imgs: {path: string, alt: string}[];

  constructor(pId: number, pTitle: string, pDetails: string[], pImg: string, pPath: string, pImgs: [{path: string, alt: string}]){
    this.id = pId;
    this.title = pTitle;
    this.img = pImg;
    this.details = pDetails;
    this.path = pPath;
    this.imgs = pImgs;
  }
}
export function autoCompleteDetails(details: string, category: number): string[]{
  let completeDetails = details;
  if(details === "" || details === null || details.length ===0){
      switch (category) {
          case 1:
              completeDetails = "Accompagnés, vous pouvez développer rapidement votre intuition et utiliser des exercices pour commencer à comprendre vos animaux. \n Cette formation va te permettre d'explorer tes capacités, elle est ainsi là pour te donner les clés pour échanger avec les animaux. Au programme : \n - Principes de base, \n - Confiance,\n -Pratique et méditation, \n- Animal totem,\n- Pratique de groupe";
              break;
              case 2:
              completeDetails = "ET SI VOUS OBSERVIEZ, ANALYSEZ ? Vous hésitez sur les bienfaits? Obtenir la confiance, échanger avec son cheval (codes vocaux, langage corporel). \n Assouplir, débourrer en sécurité, approfondir ou améliorer le dressage (défenses, locomotion...). S'amuser, se fixer des objectifs (challenges faits de micro objectifs)";
              break;
              case 3:
              completeDetails = "ET SI VOUS OBSERVIEZ - ANALYSEZ ? Vous hésitez sur les bienfaits ? Découvrir à pied, obtenir la confiance, échanger avec son cheval (codes vocaux, langage corporel). Perfectionnement monté : mettre en avant le dressage sur un parcours fixe, approfondir ou améliorer sa technique, LE TOUT EN S'amusant et se fixant des objectifs. Jeunes chevaux bienvenus !!";
              break;
              case 4:
              completeDetails = "Dressage";
              break;
              case 5:
              completeDetails = "Coaching CSO";
              break;
              case 6:
              completeDetails = "Vous rêvez d'une journée d'émotions avec votre cheval ? \n Accompagnez-nous et vivez des sensations inédites!";
              break;
              case 7:
              completeDetails = "Pour l'Initiation ou perfectionnement, l'évolution de cette séance sera individuelle. Ainsi, chacun pourra à sa convenance évoluer vers des objectifs differents, tout en étant dans le groupe. \n La diversité des profils d'obstacles vous permettra d'évoluer sereinement avec votre monture... à pied ou monté. ";
              break;
              case 9:
              completeDetails = "Assouplir, débourrer en sécurité, dresser | conserver la locomotion du cheval.\nLe travail aux longues rênes permet de régler la plupart des problèmes rencontrés à cheval.";
              break;        
              
              default:
              break;
      }
  }
  if(completeDetails !== "" && completeDetails.length>0){
  const tableDetails = completeDetails.split("\n");
    return tableDetails;
  }else{
    return [];
  }
}
export const areEqualDates=(date1: Date, date2: Date)=>{
  const tempDate1 = new Date(date1);
  const tempDate2 = new Date(date2);
  return (new Date(tempDate1.getFullYear(), tempDate1.getMonth(), tempDate1.getDate()).getTime()) === (new Date(tempDate2.getFullYear(), tempDate2.getMonth(), tempDate2.getDate()).getTime());
}
   /**
    * 
    * @param param0 BASICS
    * @returns 
    */
   let options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'long'
};
const localeFormat = "fr-fr";
options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
export const convertDateToFr=(date: Date)=>{
    return date.toLocaleDateString(localeFormat, options);
}
export const handleIconDisplay = (category: number, iconHtmlClass: string|null|undefined) =>{
    
  switch (category) {
      case 1:
      return <GiMeditation className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>
      
      case 2:
      return <GiFlagObjective className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
    
      case 3:
      return <FaHatCowboy className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
   
      case 4:
      return <GiTopHat className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
     
      case 5:
      return <GiTrophyCup className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
      
      case 6:
      return <GiAtSea className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
      
      case 7:
      return <FaHatCowboy className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
      
      case 9:
      return <FaHorse className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
      
      case 10:
        return <FaHatCowboy className={iconHtmlClass ? iconHtmlClass : 'home-actu-icon'}/>;
          
      default:
      break;
  }
}
export const navElts = [
    {id: 0, label: "Présentation", path: "/"},
    {id: 1, label: "Enseignement", path: "/enseignement"},
    {id: 2, label: "Accompagnement", path: "#"},
    {id: 3, label: "Communication animale", path: "/comanimale"},
    {id: 4, label: "Actualités", path: "/actualites"},
    {id: 5, label: "Formules", path: "/tarifs"},
    {id: 6, label: "Contact", path: "/contact"},
   
  ]
  export const subMenu = [
    {id: 20, label: "Présentiel", path: "/prestations"},
    {id: 21, label: "En ligne", path: "/partage"}
  ]
  export const navEltsFooter = [
    {id: 0, label: "Présentation", path: "/"},
    {id: 1, label: "Enseignement", path: "/enseignement"},
    {id: 3, label: "Communication animale", path: "/comanimale"},
    {id: 4, label: "Actualités", path: "/actualites"},
    {id: 5, label: "Formules", path: "/tarifs"},
    {id: 6, label: "Contact", path: "/contact"},
    {id: 7, label: "Témoignages", path: "/temoignages"},
    {id: 8, label: "Mentions légales", path: "/mentionslegales"},
    {id: 20, label: "Accompagnement présentiel", path: "/prestations"},
    {id: 21, label: "Accompagnement en ligne", path: "/partage"},
    {id: 22, label: "Inscriptions", path: "/reservation"},
   
  ]
export const witnessesData : CwitnessData[] = [
  {id: 0, title: "Communication animale", details: [
    "J'ai fait stage de communication animale, je voulais réussir à mieux comprendre mes animaux et pouvoir les aider au quotidien.",
    " Un monde incroyable s'est ouvert à moi grâce à l'accompagnement d'Ingrid. ",
    " Malgré mes craintes elle a su m'aider et me faire atteindre mon objectif.",
    " De plus le suivi via le groupe permet de s'entraîner malgré la fin du stage ce qui permet de continuer à pratiquer, s'améliorer tout en étant guidé et ça c'est vraiment chouette.",
    " Je recommande à 100% : très très satisfaite de ce stage ! "], signature: "Sarah", photo: sarah},
    {id: 1, details: [
      "Je suis très contente de ton travail, et j'ai espoir d’être contente du mien par la suite.",
      " Je te remercie du fond du coeur et je te conseillerais si on me demande !!",  
      " En tout cas je te souhaite que le meilleur et je reviendrai vers toi si j'en ressens le besoin, pour Athos, ou même pour d'autres de mes petits loulous si besoin. ",
      " Encore merci a toi "
    ], signature: "Fantine", photo: fantine},
    {id: 2, details : [ "Le stage de communication animale m'a permis d'évoluer sur tout ce qui est énergie, bien évidemment, mais aussi sur moi même. ",
    "Ingrid est d'une grande écoute, empathique, généreuse et une supère formatrice. Dans ces formations, il n'y a aucun jugements, aucune pressions et tout le monde avance à son rythme.",
    "Ce qu'il y a également de super, est le suivi post-formation. On a si je ne dis pas de bêtises, 1 animal par mois pour continuer à nous entraîner, à nous former ; ",
    "Ingrid est là pour nous guider, nous éclairer, nous entraîner, nous accompagner et nous permettre d'évoluer dans cette voix là.", 
    "De plus, la formation est vraiment accessible financièrement parlant comparé à d'autres",    
    "Je ne peux que recommender sa formation."], signature:"Gaelle F.", photo: gaelleF},
    {id: 3, title: "Coaching", details: [
    "Je recommande à 100% !",
    "Après 1 an et demi de recherches et d'essais de coachs, j'ai rencontré Ingrid qui a su comprendre mes attentes et mes besoins.",
    "À l'écoute des émotions de mon cheval mais également des miennes elle a su s'adapter à notre couple en son entièreté.",
    " Elle nous permet de travailler ma problématique en douceur et de nous apporter la technicité qui nous manquait. Je ne peux que la recommander."
  ], signature: "Eva B.", photo: eva},
  {id: 4, details: [
    "Ingrid est beaucoup plus qu'une bonne, performante, monitrice d'équitation. ",
    "Donc à apprécier et rechercher pour la technique qu'elle offre.",
    "Ce qui est déjà précieux quand on veut progresser à cheval ! ",
    "Elle a un petit plus, assez... Magique & Un 6e sens... ",
    " Dans ce qu'elle ressent immédiatement en nous et ce qu'elle perçoit également de l'animal, en l'occurrence du cheval dans le cadre de l'équitation puisque dans mon cas j'ai eu la chance de faire quelques cours avec elle. ",
    "C'est une personne très bienveillante, le mot est absolument vrai dans son cas, très à l'écoute, et très sensorielle je dirais. ",
    "Je pense que c'est tout cela qui lui donne cette capacité de percevoir des choses. Et donc d'aller loin dans le soutien et le progrès à apporter ! ",
    "Ressentir, percevoir, deviner, et donc débloquer des situations sans même que l'on ait à identifier nous mêmes le problème ou qu'on sache même qu'on l'a. C'est son grand atout. ",
    "Elle semble communiquer avec l'insondable, c'est pour cela que je parlais de magie pour le dire de manière drôle, simple. ",
    " C'est très réconfortant d'être auprès d'elle en sachant qu'elle ne juge pas, qu'elle écoute toujours avec attention et profondeur, qu'elle en tire des éléments qui vont nous pousser en avant et nous aider, même plus loin que l'équitation. ",
    " Ses autres atouts, car elle en a beaucoup : sa gentillesse, son écoute, son intérêt pour les gens et son humeur pétillante ! ",
    " Et aussi une franchise qui fait souvent du bien."
  ], signature: "Salwa", photo: salwa},
  {id: 5, details: [
    "Ingrid est quelqu'un d'humain, avec de belles valeurs et un esprit qui fuse à toute vitesse !",
    " Je n'ai jamais rencontré une monitrice aussi géniale pour les chevaux et le cavalier et encore, je pèse mes mots. Elle a une sensibilité que peu ont, une douceur à toute épreuve, une énergie débordante et un rire hyper communicatif ! ",
    " Elle a fait des cours avec 2 de mes DPs et 2 de mes chevaux et je lui fais confiance à 100% autant à pied que monté. Elle donne les bases, les codes et les techniques que nous n'avons pas et est toujours là pour de bons conseils."
  ], signature: "Gaëlle P.", photo: gaelleP},
  {id: 6, details: [
    "J’ai connu Ingrid dans le club où je pratiquais l’équitation. La première chose qui m’a agréablement surprise chez elle, c’est son souci du bien-être animal et du bien-être du cavalier.",
    " Elle arrive à être à l’écoute des deux et nous conseille toujours dans la bienveillance mais surtout dans la bonne humeur. A mon regret, les cours passaient trop vite.",
    " Ses cours sont ludiques. De plus, grâce à Ingrid, j’ai pu m’initier à plusieurs types de travail avec un cheval (longe, longues rênes, monte à cru, treck, équifeel et équifun notamment sans oublier une partie des soins, enseignement important que beaucoup d’enseignants négligent).",
    " Elle a réussi à me redonner confiance à l’obstacle, tout en m’encourageant, me conseillant, dédramatisant et en me donnant un cheval avec lequel elle savait pertinemment que je n’aurai pas de souci.",
    " Ce que j’adorais aussi avec Ingrid, c’est qu’après chaque exercice, nous faisions un bilan sur ce qui n’a pas été, ce qui a fonctionné mais surtout notre ressenti (à l’aise, pas à l’aise, qu’est-ce que je peux améliorer par exemple). Cela nous permettait de réaliser de nos progrès et de nos difficultés. En parler me permettait de dédramatiser mais surtout de m’améliorer. Les conseils sont donnés avec une empathie incroyable mais surtout une justesse de faire.",
    " Depuis, Ingrid est devenue une vraie amie. Nous sommes en contact. Elle m’a d’ailleurs aidée dans ma recherche d’achat d’un cheval et d’une écurie pour lui.",
    " Étant très intéressée par la communication animale, j’ai enfin pu réaliser mon rêve. J’ai pu participer à un stage d’initiation en janvier dernier. Très encourageante et confiante dans notre capacité de faire, ce stage fut une vraie révélation pour moi.",
    " Outre monter à cheval et pratiquer la communication animale, Ingrid m’a démontré que le pouvoir de croire en soi est une clé dans la réussite.",
    " Je recommande vivement Ingrid !!!",
    " Je regrette qu’elle soit partie de notre club. J’ai vraiment appris beaucoup avec elle."
  ], signature: "Hélène", photo: helene},
  {id: 7, details: ["J’ai tout de suite accroché avec la manière de penser et de voir le cheval d’Ingrid. "+"Ma jument de 2 ans 1/2 Kumi et moi sommes arrivées à Toulouse en septembre 2022 et nous avons rapidement fait la connaissance d’Ingrid qui donnait des cours aux écuries où nous étions. "+" Elle m’a été recommandée par une autre propriétaire de jeune cheval et j’ai regardé un de ses cours de travail à pied. "+
    " Après avoir été franchement dégoûtée du monde équestre notamment dans les clubs je cherchais à établir une vraie relation avec ma jument que je n’avais que depuis 4 mois. Je pensais pouvoir me débrouiller seule mais j’ai vite compris qu’éduquer une jeune cheval n’était pas de tout repos et qu’une aide extérieure serait la bienvenue alors j’ai décidé de tenter un premier cours avec Ingrid. "+ 
    " Le premier cours c’est beaucoup d’informations d’un coup mais c’est tellement intéressant ! Ingrid est très à l’écoute du cheval (par exemple elle a su m’expliquer les réactions et les comportements de Kumi à peine entrées dans le manège) mais elle est aussi à notre écoute ! "+ " Elle se soucie vraiment de si on assimile les informations qu’elle nous donne, si on est ok avec ce qu’il se passe, avec notre ressenti."+ 
    " Depuis ce cours-là j’enchaîne les cours de travail à pied avec Ingrid qui sont tous aussi variés les uns que les autres, sorties en extérieur, découverte de la longe, du saut, de tous les équipements : mors, filet, tapis, surfaix. À chaque fois j’ai vraiment hâte de mes cours, Ingrid (et ma Kumi évidemment) m’ont vraiment redonné goût au monde équestre comme je le rêvais. Je pense pouvoir dire que Kumi apprécie elle aussi les cours puisqu’ils se font dans le jeu, dans le respect du cheval et je pense qu’elle est plus que contente de découvrir certaines choses comme le saut qu’elle semble beaucoup apprécier !"+
    " Je suis tellement contente d’avoir croisé le chemin d’Ingrid qui nous apporte énormément à Kumi et à moi, je lui fais entièrement confiance dans ce qu’elle nous propose et j’ai hâte de vivre la suite avec elle !!"], signature: "Victoire V.", photo: victoire},
  {id: 8, title: "Stages", details: [
    "Je n'ai fait qu'un stage d'une demi journée avec Ingrid. Mais j'ai adoré et ma jeune pur sang réformée des courses aussi.",
    "Ingrid a su immédiatement trouver les mots pour moi et les solutions d'apprentissage pour ma jument. Elle a su me montrer que nous étions un vrai couple avec ma jument. ",
    "Ingrid a su conforter ma confiance en moi et en ma jument.",
    "Merci encore pour ta bonne humeur et ta bienveillance"
  ], signature: "Charlotte S.", photo: charlotte},
  {id: 9, title: "Travail à pied", details: [
    "Ingrid a très rapidement saisi les besoins, les enjeux, et a construit des séances personnalisées pour me faire progresser avec franchise et sérénité et toujours en privilégiant la sécurité du cavalier et du cheval et leur respect mutuel.",
    "Le besoin initial : découvrir le travail à pied et prendre confiance avec un \"gros\"",
    "Lors de chaque séance, j'ai ressenti une très nette évolution au fil de la séance. J'en ai vraiment tiré de très belles expériences, sensations, avec l'impression d'avoir réellement progressé sur certains points.",
    "Et toujours en finissant sur une touche positive. Cela permet aussi de remettre en question sa pratique, dans la bienveillance, et de continuer à y réfléchir après la séance." 
  ], signature: "Auriane R.", photo: auriane},
  {id: 10, title: "Coaching" , details: ["Monitrice très professionnelle à l'écoute du cavalier et du cheval, d'une gentillesse incroyable et qui fait progresser autant le cavalier que le cheval. Très bonne expérience à essayer"], signature: "Léa T.", photo: lea},
  {id: 11, details: ["Super professionnelle, qui sait prendre le temps autant avec l’humain que l’animal. Merci à elle de m’avoir aidé dans les moments compliqués comme les plus faciles. Je la recommande sans l’once d’une hésitation !!"], signature: "Aurélie : Monitrice", photo: aurelie},
  {id: 12, details: ["Très bonne expérience pour mon cheval et moi même. Je recommande"], signature: "ln floqué", photo: lnFloque},
  {id: 13, details: ["Le top du top ! Ingrid sait faire avancer et progresser ses cavaliers tout en douceur, elle leur fait partager sa passion.", "Vous pouvez y aller les yeux fermés"], signature: "Sabrina", photo: sabrina},
  {id: 14, details: ["Monitrice au top qui sait s'adapter aussi bien aux cavaliers qu'aux chevaux, je recommande les yeux fermés"], signature: "Agnes", photo: agnes},
  {id: 15, details: ["Monitrice au top 👌 je conseille ++", " pédagogue,  à l’écoute, "," douce, "," explicite"], signature: "Clémence Shamence", photo: clem},
  {id: 16, details: ["J’ai connue Ingrid lorsqu’elle était élève monitrice et déjà on sentait qu’elle savait de quoi elle parlait. ", "Elle propose des activités variées en fonction de nos objectifs et nos envies . Le respect du cheval et son bien être sont également importants pour Ingrid.","Je recommande chaudement de faire appel à ses services !"], signature: "Camille", photo: camille},
  {id: 17, details: ["Je recommande sans hésiter", "J'ai eu Ingrid comme monitrice. ","Je garde un très bon souvenir de ses cours (collectifs comme particuliers)."], signature: "Léa P.", photo: leaP},
  {id: 18, details: ["Je recommande Ingrid autant en tant que monitrice que pour la com animale.","Elle m'a fait comprendre des choses sur mon chien dont je me doutais déjà un peu, mais qui m'ont ouvert les yeux.",
  "En tant que monitrice elle est à l'écoute des demandes du cavalier et du cheval, ce qui ne se fait malheureusement plus beaucoup. Et elle est top avec les jeunes chevaux"], signature: "Sandra", photo: sandra},
]

 const priceDetailsLessons: CpriceDetails[]=[
  {id: 0, title: "Cours particulier", label: "La séance", label2: "Prévoir location de structures si cavalier extérieur", price: "35"},
  {id: 1, title: "Cours particulier", label: "Carte 4 séances par mois", label2: "Prévoir location de structures si cavalier extérieur", price: "128"},
  {id: 2, title: "Travail du cheval", label: "La séance", price: "35"},
  {id: 3, title: "Cours & stage sur structure", label: "À l'heure", price: "Sur devis"}
]
 const priceTraining: CpriceDetails[]=[
  {id: 0, title: "Stage", label: "Demi journée",  price: "40", label2: "Prévoir location de structures si cavalier extérieur"},
  {id: 1, title: "Stage", label: "Journée complète", label2: "Prévoir location de structures si cavalier extérieur", price: "70"},
  {id: 2, title: "Stage Cross | Mountain trail", label: "Demi journée", label2: "Location des structures comprise", price: "65"},
  {id: 3, title: "Stage d'initiation à la Communication Animale", label: "Journée complète", label2: "Prévoir frais de déplacement si autre région",  price: "150"}
]
 const priceCom: CpriceDetails[]=[  
  {id: 0, title: "Communication Animale", label: "La séance", label2: "",  price: "50"},
  {id: 1, title: "Formation Communication Animale", label: "Modules 1 et 2", label2: "Formule autonomie",  price: "200"},
  {id: 2, title: "Formation Communication Animale", label: "Modules 1 et 2", label2: "Formule intuition",  price: "300"}
]
 const priceLive: CpriceDetails[]=[
  {id: 0, title: "Coaching Live", label: "La séance", label2: "",  price: "35"},
  {id: 1, title: "Coaching Live", label: "Forfait 4 séances par mois", label2: "Durée mini : 4 mois", price: "100"},
  {id: 2, title: "Coaching Live", label: "Forfait 2 séances par mois", label2: "Durée mini : 4 mois", price: "56"}
]
 const priceVideo: CpriceDetails[]=[
  
{id: 0, title: "Coaching Vidéo", label: "La séance", label2: "Préparation | exercices | correction", price: "27"},
{id: 1, title: "Coaching Vidéo", label: "Forfait 4 séances par mois", label2: "Préparation | exercices | correction", price: "86"},
{id: 2, title: "Coaching Vidéo", label: "Forfait 2 séances par mois", label2: "Préparation | exercices | correction", price: "46"},
{id: 3, title: "Coaching Live & Vidéo", label: "Forfait 1 mois", label2: "1 coaching live + 3 coachings vidéo", price: "100"}
]

export const priceData: CPriceData[]=[  
  {index: 0, id: "lessons", title: "Les cours", priceDetails: priceDetailsLessons},
  {index: 1, id: "training", title: "Les stages", priceDetails: priceTraining},
  {index: 2, id: "com", title: "La communication animale", priceDetails: priceCom},
  {index: 3, id: "live", title: "Coaching Live", priceDetails: priceLive},
  {index: 4, id: "video", title: "Coaching Vidéo", priceDetails: priceVideo}
]

export const trainingData: CTrainingData[]=[
{id: 0, date: "25 février 2023", title: "Stage travail à pieds",  details: "etho-equifeel & problématiques. Groupes de travail", at: "Écurie du Château | Villematier (31)"},
{id: 1, date: "26 février 2023", title: "Cross",  details: "Groupes de travail | longe pour la découverte | monté", at: "Les Écuries d'En Graougnou (31)"}
]
export const PassedActuData: COldActuData[]=[
  {id: 0, date: "25 février 2023", title: "Stage travail à pieds",  details: "etho-equifeel & problématiques. Groupes de travail", at: "Écurie du Château | Villematier (31)", path: actuPied, icon: <GiFlagObjective className='bubble-icon'/>},
  {id: 1, date: "26 février 2023", title: "Cross",  details: "Groupes de travail | longe pour la découverte | monté", at: "Les Écuries d'En Graougnou (31)", path: actuCross, icon: <FaHatCowboy className='bubble-icon'/>}
  ]
export const FurtherActuData: COldActuData[]=[
  {id: 0, date: "12 mars 2023", title: "TREC POR ET PTV",  details: "TREC - POR le matin | Ptv après-midi", at: "31", path: actuTrec, icon: <FaHatCowboy className='bubble-icon'/>},
  {id: 1, date: "19 mars 2023", title: "Coaching CSO officiel", details: "Coaching CSO officiel", at: "31", path: actuCoaching01, icon: <GiTrophyCup className='bubble-icon'/>},
  {id: 2, date: "16 avril 2023", title: "Communication animale", details: "Stage de communication animale", at: "31", path: "", icon: <GiMeditation className='bubble-icon'/>},
  {id: 3, date: "22 avril 2023", title: "Communication animale", details: "Stage de communication animale", at: "82", path: actuCom, icon: <GiMeditation className='bubble-icon'/>},
  {id: 4, date: "23 avril 2023", title: "Coaching CSO officiel", details: "Coaching CSO officiel", at: "31", path: actuCoaching02, icon: <GiTrophyCup className='bubble-icon'/>}

]
export const homeArticles : ChomeArticleData[]= [
  {id: 1, title: "Mon enseignement", details: [
    "Un enseignement diversifié pour la réussite dans la décontraction.",
    "La connexion commence à pied, avec l'equifeel (dialogue et réussite commune sur des challenges progressifs), l'étude du comportement et les longues rênes ;  elle prend tout son sens en dressage et en équitation de travail, où le couple ne fait plus qu'un."
  ], img: home03, path:"/enseignement", imgs: [
    {path: homeTeachBlock01, alt: "enseignement01"},
    {path: home03, alt: "home03"},
    {path: homeTeachBlock02, alt: "enseignement02"},
    {path: homeTeachBlock03, alt: "enseignement03"},
    {path: homeTeachBlock04, alt: "enseignement04"},
 
  ]},
  {id: 0, title: "Sa pédagogie", details: [
    "Ingrid privilégie le dialogue et adapte ses réponses aux difficultés rencontrées.",
      "À la fin d'un cours, elle recueille les impressions des élèves ; ainsi, l'analyse de sa séance permet à chaque cavalier de mieux comprendre en formulant son ressenti."
  ], img: home02, imgs: [
    {path: home02, alt: "pédagogie"},
    {path: homePedaBlock01, alt: "pédagogie02"},
    {path: homePedaBlock02, alt: "pédagogie03"},
    {path: homePedaBlock03, alt: "pédagogie04"},
    {path: homePedaBlock04, alt: "pédagogie05"}   
  ]},
  {id: 2, title: "Accompagnements", details: [
    "Cours particuliers ou collectifs, Ingrid propose aussi ses services aux structures équestres dans les départements suivants : 31, 32, 81 et 82",
    "Elle encadre petits et grands avec la même énergie, tout en s'adaptant au niveau et à l'âge de chacun. Ingrid propose aussi le travail du cheval, à pied ou monté, dans des disciplines variées. Journées de stage thématiques, sorties au lac ou à la mer, elle adore proposer de nouveaux challenges à ses élèves !"
  ], img: home04, path:"/prestations", imgs: [
    {path: homePrestaBlock05, alt: "prestations"},
    {path: homePrestaBlock01, alt: "prestations image01"},  
    {path: homePrestaBlock02, alt: "prestations image02"}, 
    {path: homePrestaBlock03, alt: "prestations image03"},
    {path: homePrestaBlock04, alt: "prestations image04"},
  ]},
  {id: 13, title: "Le partage", details: [
    "Ingrid vous propose des cours en ligne, avec vidéo et suivi personnalisé. Accédez à la plateforme d'apprentissage en un simple clic",
  ], img: home05, path:"#", imgs: [
    {path: home06, alt: "partage"}   
  ]}

];

export const together : CTogetherArticleData[]=[
  {id: 0, title: "Au lac",img: [lac01, lac02, lac03, lac05]},
  {id: 1, title: "À la mer",img: [mer01, mer02, mer03]},
  {id: 2, title: "Au cross", img: [cross01, cross02, cross03, cross04, cross05]},
  {id: 3, title: "PTV", img: [ptv01, ptv02, ptv03, ptv04, ptv05, ptv06, ptv07, ptv08, ptv09, ptv010]}
 
]
export const carouselData=[
  {title: "Au lac", path: lac01}, 
   {title: "Au lac", path: lac02},
   {title: "Au lac", path: lac03},
   {title: "Au lac", path: lac05},
   {title: "À la mer", path: mer01},
   {title: "À la mer", path: mer02},
   {title: "À la mer", path: mer03},
   {title: "Stage cross", path: cross01},
   {title: "Stage cross", path: cross02},
   {title: "Stage cross", path: cross03},
   {title: "Stage cross", path: cross04},
   {title: "Stage cross", path: cross05},
   {title: "Stage PTV", path: ptv01},
   {title: "Stage PTV", path: ptv02},
   {title: "Stage PTV", path: ptv03},
   {title: "Stage PTV", path: ptv04},
   {title: "Stage PTV", path: ptv05},
   {title: "Stage PTV", path: ptv06},
   {title: "Stage PTV", path: ptv07},
   {title: "Stage PTV", path: ptv08},
   {title: "Stage PTV", path: ptv09},
   {title: "Stage PTV", path: ptv010}
   

]
export const carouselPartage=[
   {title: "Coaching Live", path: live02}, 
   {title: "Coaching Vidéo", path: live01},
   {title: "Communication Animale", path: live03},
   {title: "Formation Communication Animale", path: live04}   
]
export const carouselPresentiel=[
  {title: "Cours particuliers", path: coursPart}, 
  {title: "Cours collectifs propriétaires et structures", path: coursCo},
  {title: "Travail du cheval", path: travailCheval},
  {title: "Aide au débourrage", path: aideDebourrage},
  {title: "Communication animale", path: comAnimalePresta},
  {title: "Coaching concours", path: coachingConcours},
  {title: "Stages et sorties", path: stagesSorties}
]
export const carouselHome=[
  {title: "Enseignement", path: home01}, 
  {title: "Accompagnement", path: live01},
  {title: "Communication Animale", path: live03},
  {title: "Partage", path: live04}   

]
interface Ipartage{
  title: string, 
  path: string, 
  content: string[],
  practice: JSX.Element
}

 
const articleConst = {mail: "equilys.ingrid@gmail.com", site : "equilys.ingrid.fr", tel : "06 22 94 11 67", label: "Ingrid Equilys"}


export const articlePartage: Ipartage[]=[
  {title: "Coaching Live", path: partageArticleLive, 
   content: [
    "Le coaching live, c'est un enseignement à distance en visioconférence.", 
    " Si vous habitez trop loin ou êtes dans l'impossibilité de vous déplacer avec votre monture, Ingrid vous propose un suivi personnalisé sur rendez-vous via la plateforme Zoom©."
  ], 
  practice: 
  <div className='partage-article-practice'>
  <span className='partage-article-data-title'>En pratique</span>
  <div className='partage-practice'>  
    Vous complétez, téléchargez et envoyez par mail à <EquilysMail/> la <TargetFile/>  téléchargeable <a href={'/reservation'}>ici</a> pour faire connaissance.
    Une fois cette fiche reçue, Ingrid prend contact avec vous afin :
    <CgvParagraph>- De trouver des disponibilités communes,</CgvParagraph>
    <CgvParagraph>- De préparer la séance : installation du matériel et des dispositifs nécessaires,</CgvParagraph>
    <CgvParagraph>- De procéder au règlement. </CgvParagraph>
    <CgvParagraph> La connexion peut alors s'engager via la plateforme Zoom©. Le coaching "enseignement en LIVE", commence dix minutes avant le cours afin de synchroniser nos connexions, pour une durée d'une heure, soit soixante minutes de cours.</CgvParagraph>
  </div>
   </div>
  },
  {title: "Coaching Vidéo", path: partageArticleVideo,
  content: [
    "Le coaching vidéo permet d'avoir un retour sur le travail effectué avec votre cheval de façon régulière. ",
    " Il s'agit d'un suivi sur le temps, avec envoi de vidéos et retour d'un audio correspondant. Dans cet audio : des conseils, pistes de travail et de positions, rectification de ces petites imperfections qui nuisent à une communication claire et harmonieuse avec notre cheval. ", 
    " Ce procédé peut compléter un suivi présentiel et/ou live, mais une séance sera aussi constructive en autonomie ou en coaching présentiel."], 
    practice: 
    <div className='partage-article-practice'>
  <span className='partage-article-data-title'>En pratique</span>
  <div className='partage-practice'>
    Le coaching "enseignement et correction de vidéos" commence le 1er jour du mois suivant la réception de votre règlement.
  </div>
  <div className='partage-practice'>  
    Vous complétez, téléchargez et envoyez par mail à <EquilysMail/> la <TargetFile/>  téléchargeable <a href={'/reservation'}>ici</a> pour faire connaissance.
    Une fois cette fiche reçue, Ingrid prend contact avec vous afin :
    <CgvParagraph>De trouver des disponibilités communes et de préparer la séance : installation du matériel et des dispositifs nécessaires.</CgvParagraph>
    
    <CgvParagraph> 
    Ingrid vous explique alors le déroulé de la prochaine séance de travail, pensé au mieux en fonction des objectifs que vous vous êtes fixés.
    </CgvParagraph>
    <CgvParagraph>Une fois votre séance filmée, vous envoyez une première vidéo permettant à Ingrid de visionner les premiers exercices et d'en faire un retour commenté par audio.</CgvParagraph>
    <CgvParagraph>
    Ainsi, des exercices personnalisés vous sont ensuite proposés afin d'améliorer les difficultés rencontrées ou conforter des acquis.
    </CgvParagraph>
  </div>
   </div>
},
  {title: "Communication Animale", path: partageArticleCom,
  content: [
    "Ingrid pratique la communication animale depuis son plus jeune âge.",
    " Les séances peuvent se faire avec ou sans la présence du propriétaire de l'animal.",
    " Une page spéciale est dédiée à cet art atemporel qu'elle se propose de vous faire partager."
  ], 
    practice: <div className='partage-article-practice'>
    <span className='partage-article-data-title'>En savoir plus...</span>
    <CgvParagraph> Découvrez la communication animale en présentiel ou à distance</CgvParagraph>
  </div>
},
  {title: "Formation Communication Animale", 
  path: partageArticleComTeaching,
  content: [
    "La communication animale est un avoir ancestral à la portée de tous.",
    " Ouvrez-vous à une perception différente et commencez à comprendre vos animaux ! Votre motivation est source de votre réussite.",
    "En plus des stages qu'elle organise régulièrement en partenariat avec des écuries, Ingrid vous propose maintenant une formation en ligne sur trois modules : de l'initiation à l'expertise."
  ], 
    practice: <div className='partage-article-practice'>
    <span className='partage-article-data-title'>En savoir plus...</span>
    <CgvParagraph>Découvrez la formation communication animale en présentiel ou à distance</CgvParagraph>
  </div>
}   

]
export const prestations : CPrestaArticleData[]= [
  {id: 0, title: "Cours particuliers", subtitle: "Son engagement : " ,
  details: [   
    " Faire en sorte de vous proposer une équitation qui correspond à vos envies et vos ambitions, en respectant les capacités physiques et mentales de votre cheval.",
    " Disciplines proposées en coaching individuel sur rendez-vous : ",
    " Dressage, obstacle, travail à pied (equifeel / etho), longues rênes, TREC...",
  ], img: [cp03, cp01, cp02, coachingIndividuel01], button: "contact"},
  {id: 1, title: "Cours collectifs propriétaires et structures", details: [
    "Ingrid assure les coachings de groupe et intervient dans les centres équestres | poneys clubs pour assurer des remplacements ou encadrer des stages. "
    
  ], img: [ encadrementEnfants01, encadrementEnfants02, encadrementAdultes], button: "contact"},
  {id: 2, title: "Travail du cheval", details: [
    "Longe, longues rênes, monté sur le plat ou à l'obstacle, Ingrid s'adapte aux besoins de chaque couple propriétaire | cheval. ",
    "Elle apporte des réponses aux problématiques par le dialogue et l'écoute.",
    "Comme pour l'enseignement, un debriefing de fin de séance permet au cavalier de travailler dans le même sens. "
  ], img: [ETCabre, jaleante, longuesRenes02, longuesRenes03], button: "contact"},
  {id: 3, title: "Aide au débourrage", details: [
    "Un débourrage en toute sérénité, c'est un débourrage progressif, au rythme de votre cheval.",
    " L'habituation aux gestes du quotidien, le travail à pied et aux longues rênes aideront le jeune cheval à apprendre les aides et appréhender facilement le travail monté  "
  ], img: [aideDebourrage01, aideDebourrage04,  aideDebourrage02], button: "contact"},
  
  {id: 4, title: "Communication animale", details: [
    "Parfois, le ressenti ne suffit plus et le besoin de savoir devient une nécessité. ",
    "Sur demande, Ingrid communique avec votre compagnon et vous ouvre les portes du dialogue. "
  ], img: [comAnimale, com04, com05], button: "comanimale"},
  {id: 5, title: "Coaching concours", details: [
    "Pour un accompagnement complet, Ingrid coach en concours TREC et CSO."
    
  ], img: [coachingCollectif,coachingTrec, coachingIndividuel02,coachingEnfants01], button: "contact"},
  
  {id: 6, title: "Stages et sorties", subtitle: "Un objectif : progresser ensemble", details: [
    "Equifeel, travail à pied, longues rênes, TREC, PTV, montain train et bien sûr, communication animale : chaque thème ouvre de nouvelles perpectives de découvertes entre le cavalier et son cheval. ",
    " Au-delà de se faire plaisir ensemble, les sorties participent à forger le mental."
    
  ], img: [stages01, mer02, ptvRond, lac07], button: "actualites"}
  
 
]
export const enseignementData : CteachingData[] =[
{
  id: 0, 
  title: "Communication animale, savoir être", 
  details: [
    "Méditation et communication pour y voir plus clair :",
    "La communication animale, Ingrid se propose de vous y initier en stages et d’approfondir cette connexion ! ",
    " « C'est en effet un savoir ancestral qui se transmet et s'acquiert sans aucun prérequis suivant trois étapes : apprendre, comprendre, appliquer.",
    " COMMENT ACQUÉRIR L'ÉTINCELLE DE LA COMMUNICATION ANIMALE ? Vous pouvez développer rapidement votre intuition et utiliser des exercices pour commencer à comprendre vos animaux. »"
  ], 
  
  icon: <GiMeditation className='bubble-icon'/>, 
  svg: undefined},
  {id: 1, 
    title: "Désensibilisation et agilité", 
    details: [
      "Ingrid enseigne également l'équifeel et l'equifun pour initier les cavaliers à une autre sensibilité.",  
      " Le but : désensibiliser grâce à un parcours d’embûches et habituer votre cheval à différents stimulus.",
     " La discipline se veut éducative et ludique au travers de tests à réaliser avec sa monture, dans le calme et le respect mutuel. »",
     " La règle des 3C  (Confiance, Contrôle et Connexion) permet la réalisation de ces exercices, avec les objectifs suivants :",
     "- Autonomie : développer le courage, la réflexion et la proprioception",
     "- Observation et analyse : anticiper & gérer vos émotions (stress, fuite…), ",
     "- Communication : sortir de sa zone de confort"
    ], 
    icon: <GiFlagObjective className='bubble-icon'/>, 
    svg: undefined},
    {id: 2, 
      title: "Longues rênes", 
      details: [
        "La maîtrise des longues rênes doit s'acquérir assez tôt ; elle est d'ailleurs maintenant intégrée au programme de validation des galops de la FFE.",
        " Les bonnes raisons : ",
        "Échanger, observer et analyser avec votre cheval en vous incitant à travailler sur votre langage corporel et vos codes vocaux. ",
        "Assouplir, débourrer en sécurité, dresser | conserver la locomotion du cheval.",
        "Le travail aux longues rênes permet de régler la plupart des problèmes rencontrés à cheval. ", 
        "Pratiqué à l'épaule, il ouvre la voie au travail de haute école et muscle le cheval tout au long de sa vie sans le poids du cavalier."
      ], 
      icon: <FaHorse className='bubble-icon'/>, 
      svg: undefined},
      {id: 3, 
        title: "Dressage", 
        details: [
          "Général Alexis L'Hotte : « En avant calme et droit. »",
	        "« Le dressage consiste en cette permanente recherche d’esthétisme et d’élégance qui ne peut exister sans une harmonie quasi parfaite entre le cavalier et son cheval. Tout cela est le résultat d’un travail d’équipe où l’entraîneur joue un rôle essentiel. Il est le chef d’orchestre qui guide le couple dans cette recherche.",
          "Le dressage doit se pratiquer dans la décontraction et non la frustration, c'est pourquoi je veille à respecter l’échelle de progression de votre compagnon et de son cavalier. ",
          "Ceci avec toujours plus de subtilité et de finesse pour révéler le meilleur du couple. ",
	        "Le dressage permet au cheval de se forger un dos tonique, une musculature harmonieuse et une bonne souplesse jusqu'à l'abord des gestes plus techniques du travail de haute école.»",
          
        ], 
        icon: <GiTopHat className='bubble-icon'/>, 
        svg: undefined},
        {id: 4, 
          title: "Équitation d'extérieur", 
          details: [
            "Ingrid encadre aussi les disciplines d'extérieur, c'est même celles qu'elle affectionne le plus.",
            "Des disciplines exigeantes et riches en sensations : très complètes, elles mettent en valeur la complicité du couple cavalier | cheval. ",
            "Les qualités recherchées sont l'efficacité, la franchise et le style. ",
            " Les disciplines du TREC telles que le PTV et le moutain trail permettent de pratiquer à pied et de continuer l'épreuve après refus de certains dispositifs, sans élimination. De quoi ravir vos plus jeunes montures !! ",
              "Une opportunité de progresser dans des activités d'avenir. ",
            "Ingrid coach en concours et propose régulièrement des stages sur ces thématiques."
          ], 
          icon: <FaHatCowboy className='bubble-icon'/>, 
          svg: undefined},
          {id: 5, 
            title: "Obstacle", 
            details: [
             "Ingrid assure le coaching en CSO.",
             "Elle cherche avant tout à obtenir un confort de travail pour le cheval, ce qui permet d'atteindre des objectifs ambitieux.",
             " « Quoi de mieux qu'aborder les fondamentaux : la gymnastique et l’enchaînement ? Ainsi chaque phase a son importance et sera mise en avant afin de faciliter la meilleure progression possible. ",
             "Comme vous l'aurez compris c'est pour moi capital",             
             "Votre cheval charge les barres ? Il stresse à l'abord des obstacles ? Vous êtes au bon endroit pour trouver une réponse à vos questions. »"
            ], 
            icon: <GiTrophyCup className='bubble-icon'/>, 
            svg: undefined},
]