
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import savePdf from '../images/common/savePdf.png';
import UploadSign from '../components/UploadSignature';
import PdfFicheContact from '../components/PdfFicheContact';
import SavePdf from '../components/SavePdf';
import { EquilysMail } from '../components/SiteInfos';
import ButtonShine from '../components/ButtonShine';




export default function FicheContact({...props}) {
 
  const [isToPrint, setIsToPrint] = useState(false);
  const [infoUser, setInfoUser] = useState(false);
  const infoRef = useRef<any>();

  /**
    * 
    * @param param0 BASICS
    * @returns 
    */
let options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
 /*  weekday: 'long' */
};
  const localeFormat = "fr-fr";
  options = { year: 'numeric', month: 'short', day: 'numeric' };

  const [signature, setSignature] = useState<{signaturePlace: string, signatureDate: string}>({signaturePlace: "", signatureDate: new Date().toLocaleDateString(localeFormat, options)})


  const changeSignatureZone = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const {name, value} = e.currentTarget;
    setSignature({...signature, [name]: value})
}
const printDocument=()=> {
  console.log("print");
  setIsToPrint(true);      
}

  useEffect(()=>{
    if(isToPrint){       
      const input = document.getElementById('divToPrint');
  
    if(input){
        html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
          /* pdf.output('dataurlnewwindow'); */
          pdf.save("FicheContact.pdf");
          // reset printable area visible
          setIsToPrint(false);
          // info contact ok
          setInfoUser(true);
        })
        ;
    }    
  }
  if (infoUser && infoRef){
    infoRef.current.focus();   
  }
  
  }, [isToPrint]);

     
      return (
    
        <div id="divToPrint" className={isToPrint? "printable-pdf":"editable-pdf"}>             
        <PdfFicheContact 
          paymentInfos={props.paymentInfos} 
          signature={signature} 
          isToPrint={isToPrint}
        />
        <UploadSign signature={signature} isToPrint={isToPrint} changeSignatureZone={changeSignatureZone} />
        <SavePdf isToPrint={isToPrint} printDocument={printDocument}/>
        <div className={infoUser ? 'ask-user-window' : 'hidden-user-window'}>
        La fiche Contact a bien été enregistrée dans vos téléchargements.
        <p>Vous pouvez maintenant envoyer la fiche objectifs et la fiche contact par <span className="important">mail </span>
        à : <EquilysMail/> </p>
        <ButtonShine 
          theRef={infoRef}
          onClick={(e: React.MouseEvent<HTMLButtonElement>)=>{
            props.setActivForm("objectif");
            setInfoUser(false);          
          }
        } 
        label="C'est compris"/> 
      </div>
        </div>
  
      
      );
     
    }
  
